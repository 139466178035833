import React, { useEffect, useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Space, Typography, Spin, Switch } from "antd";
import styles from './style.module.scss'
import SET_ACADEMY_TICKET_POLICY from "../../graphql/setAcademyTicketPolicy";
import SET_ACADEMY_USE_PAYMENT from "../../graphql/setAcademyUsePayment";

const TICKET_POLICY_TYPE = [
    {
        type: "DAILY_MAX_TICKET_USAGE",
        title: "일별 기본 질문권 최대사용량",
        defaultValue: 3,
    },
    {
        type: "MONTHLY_RECHARGE_QUANTITY",
        title: "월별 기본 질문권 충전량",
        defaultValue: 10,
    },
]

const TicketPolicySection = ({ academyData }) => {
    const [policyVals, setPolicyVals] = useState([]);
    const [usePayment, setUsePayment] = useState(false);

    const [setAcademyTicketPolicy, mutationResult] = useMutation(
        SET_ACADEMY_TICKET_POLICY,
    );
    const [setAcademyUsePayment, setUsePaymentResult] = useMutation(
        SET_ACADEMY_USE_PAYMENT,
    )

    const academy = academyData?.academy;
    const mutationAcademyData = mutationResult?.data?.setAcademyTicketPolicy;
    const setUsePaymentAcademyData = setUsePaymentResult?.data?.setAcademyUsePayment;

    useEffect(() => {
        let targetData = [mutationAcademyData, setUsePaymentAcademyData, academyData]
            .filter(item => !!item?.academy)
            .reduce((acc, cur) => {
                if (acc == null) {
                    return cur
                }
                return acc.responseTime > cur.responseTime ?
                    acc : cur
            }, null)
        
        if (targetData) {
            let academy = targetData.academy;
            const ticketPolicies = academy.ticketPolicies;
            const policy = ticketPolicies?.[0]?.policy;
            const policyVals = TICKET_POLICY_TYPE.map((item) => ({
                ...item,
                value: policy?.[item.type] || item.defaultValue
            }));
            setPolicyVals(policyVals);
            setUsePayment(academy.usePayment);
        }
    }, [mutationAcademyData, academyData, setUsePaymentAcademyData]);

    const resetPolicyVals = useCallback(async () => {
        const policy = TICKET_POLICY_TYPE.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.type]: cur.defaultValue,
            };
        }, {});
        const variables = { academyId: academy.id, policy };
        await setAcademyTicketPolicy({ variables });
    }, [academy]);

    const changePolicyVal = useCallback((type, plus) => (e) => {
        const updatedPolicyVals = policyVals.map((item) => {
            if (item.type == type) {
                return {
                    ...item,
                    value: plus ? item.value + 1 : Math.max(item.value - 1, 0)
                }
            } else {
                return item;
            }
        });
        setPolicyVals(updatedPolicyVals);
    }, [policyVals]);

    const savePolicyVals = useCallback(async () => {
        const policy = policyVals.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.type]: cur.value,
            };
        }, {});
        const variables = { academyId: academy.id, policy };
        await setAcademyTicketPolicy({ variables });
    }, [policyVals, academy]);

    const saveUsePayment = useCallback((value) => {
        setAcademyUsePayment({
            variables: {
                academyId: academy.id,
                usePayment: value
            }
        })
    }, [academy]);


    if (!academy) {
        return <Spin />
    }
    return (
        <div className={styles.appSection}>
            <Space direction={'horizontal'}>
                <Typography.Text strong>{'질문권 결제 활성화'}</Typography.Text>
                <Switch checked={usePayment}
                    onChange={(checked) => saveUsePayment(checked)} />
            </Space>
            <Space direction={'vertical'} align="start">
                {
                    policyVals.map((item) => (
                        <Space direction="horizontal" align="center">
                            <Typography.Text strong>{item.title}</Typography.Text>
                            <Space direction="horizontal">
                                <Button onClick={changePolicyVal(item.type, false)}>-</Button>
                                <Typography.Text>{item.value}</Typography.Text>
                                <Button onClick={changePolicyVal(item.type, true)}>+</Button>
                            </Space>
                        </Space>
                    ))
                }
            </Space>
            <Space direction={'horizontal'}>
                <Button size="large" onClick={resetPolicyVals} >초기화</Button>
                <Button type="primary" size="large" onClick={savePolicyVals} >저장</Button>
            </Space>
        </div>
    )
}

export default TicketPolicySection;