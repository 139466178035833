import {Button, Descriptions, Image, Space, Table, Tag, Typography} from "antd";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import momentTz from "moment-timezone";
import constants from "../../assets/constants";
import styles from "./style.module.scss"

const UserDetailPage = () => {
    const params = useParams();
    const [userData, setUserData] = useState(null);

    const termsColumns = [
        {
            title: '제목',
            dataIndex: ['to', 'title'],
            key: 'title',
            align: 'center',
        },
        {
            title: '필수 여부',
            dataIndex: ['to', 'is_required'],
            key: 'is_required',
            align: 'center',
            render: (required) => {
                if (required) return <Tag color={'blue'}>필수</Tag>
                else return <Tag>선택</Tag>
            }
        },
        {
            title: '동의 여부',
            dataIndex: 'agree',
            key: 'agree',
            align: 'center',
            render: (required) => {
                if (required) return <Tag color={'blue'}>동의</Tag>
            }
        },
    ];
    const lectureColumns = [
        {
            title: '수업 ID',
            dataIndex: 'cid',
            key: 'cid',
            render: (text, record) => <Link to={`/lecture/${text}`}>{text}</Link>,
            align: 'center',
            width: '15%'
        },
        {
            title: '수업명',
            dataIndex: ['cid_HIQDB_class', 'name'],
            key: 'name',
            align: 'center',
        },
        {
            title: '학원 Id',
            dataIndex: ['cid_HIQDB_class', 'pid'],
            key: 'pid',
            render: (text) => <Link to={`/class-room/${text}`}>{text}</Link>,
            align: 'center',
        },
        {
            title: '유저 소유',
            dataIndex: ['cid_HIQDB_class', 'pid'],
            key: 'pid',
            render: (text, record) => {
                if (userData.id === record?.cid_HIQDB_class?.reg_id) {
                    return <Tag color="blue">소유</Tag>
                }
            },
            align: 'center',
        },
        {
            title: '수업 권한',
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                let typeName = null;
                switch (type) {
                    case 5 :
                        typeName = '수업 관리자';
                        break;
                    case 7 :
                        typeName = '조교';
                        break;
                    case 0 :
                        typeName = '학생';
                        break;
                    case 6 :
                        typeName = '선생님'
                }
                return <Tag color={type === 5 ? 'blue': ''}>{typeName}</Tag>
            },
            align: 'center',
        },
        {
            title: '참여 여부',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
                let statusTest = '참여'
                if (status === 0) {
                    statusTest = '참여 신청'
                }
                return <Tag color={status === 1 ? '' : 'blue'}>{statusTest}</Tag>
            },
            align: 'center',
        },
        {
            title: '가입일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (ans) => <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>
        }
    ];
    const classRoomColumns = [
        {
            title: '학원 ID',
            dataIndex: 'cid',
            key: 'cid',
            render: (text, record) => <Link to={`/class-room/${text}`}>{text}</Link>,
            align: 'center',
            width: '15%'
        },
        {
            title: '학원명',
            dataIndex: ['cid_HIQDB_class', 'name'],
            key: 'name',
            align: 'center',
        },
        {
            title: '유저 소유',
            dataIndex: ['cid_HIQDB_class', 'pid'],
            key: 'pid',
            render: (text, record) => {
                if (userData.id === record.cid_HIQDB_class.reg_id) {
                    return <Tag color="blue">소유</Tag>
                }
            },
            align: 'center',
        },
        {
            title: '학원 권한',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (type,record) => {
                let typeName = null;
                switch (type) {
                    case 5 :
                        typeName = '학원 관리자';
                        break;
                    case 7 :
                        typeName = '조교';
                        break;
                    case 0 :
                        typeName = '학생';
                        break;
                    case 6 :
                        typeName = '선생님'
                }
                return <Tag color={type === 5 ? 'blue': ''}>{typeName}</Tag>
            }
        },

        {
            title: '학원 참여 여부',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: '가입일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (ans) => <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>
        }
    ];
    const booksColumns = [
        {
            title: '교재 ID',
            dataIndex: 'bid',
            key: 'bid',
            render: (text, record) => <Link to={`/book/${text}`}>{text}</Link>,
            align: 'center',
            width: '15%'
        },
        {
            title: '교재명',
            dataIndex: ['bid_HIQDB_book', 'name'],
            key: 'name',
            align: 'center',
        },
        {
            title: '유저 소유',
            dataIndex: ['bid_HIQDB_book', 'reg_id'],
            key: 'reg_id',
            render: (text, record) => {
                if (userData.id === record.bid_HIQDB_book.reg_id) {
                    return <Tag color="blue">소유</Tag>
                }
            },
            align: 'center',
        },
        {
            title: '교재 권한',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (type,record) => {
                let typeName = '수업에 의한 열람';
                switch (type) {
                    case 1 :
                        typeName = '열람';
                        break;
                    case 2 :
                        typeName = '참여';
                        break;
                    case 4 :
                        typeName = '구독 학생';
                        break;
                    case 5 :
                        typeName = '관리자';
                        break;
                    case 6:
                        typeName = '일반';
                        break;
                    case 7:
                        typeName = '조교';
                        break;
                    case 8:
                        typeName = '구독 선생님';
                        break;
                    case 9:
                        typeName = '초대 대기 상태'
                }
                return <Tag color={type === 5 ? 'blue': ''}>{typeName}</Tag>
            }
        },
    ];
    const qnaColumns = [
        {
            title: '질문 Id',
            dataIndex: 'id',
            ket: 'id',
            align: "center",
            render: (id, record) => <Link
                to={`${constants.message_talk_url}/talk/student/null/${id}/${record.sid}/${record.tid}`}
                target="_blank">{id}</Link>
        },
        {
            title: userData?.user_type === 2 ? '질문한 유저' : '질문 받은 유저',
            dataIndex: 'sid',
            key: 'sid',
            render: (sid, record) => {
                if (userData?.user_type === 2 && sid && sid !== params.id) {
                    return <Link
                        to={`/user/${sid}`}>{record?.sid_HIQDB_user_credential?.user?.name}({record?.sid_HIQDB_user_credential?.user?.nick})</Link>
                } else if (userData?.user_type === 2 && sid === null) {
                    return <Tag color='blue'>모범 문제</Tag>
                } else if (userData?.user_type === 1) {
                    return <Link
                        to={`/user/${record?.tid_HIQDB_user?.id}`}>{record?.tid_HIQDB_user_credential?.user?.name}({record?.tid_HIQDB_user_credential?.user?.name})</Link>
                }
            },
            align: "center",
            filters: [
                {
                    text: '모범문제',
                    value: null,
                }, {
                    text: '학생질문문제',
                    value: 'student'
                }
            ],
            onFilter: (value, record) => {
                if (value === 'student') {
                    return record.sid !== null
                } else {
                    return record.sid === value
                }
            }
        },
        {
            title: '질문 등록일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (reg) => <span>{momentTz(reg * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            align: "center"
        },
        {
            title: '답변 완료일',
            dataIndex: 'ans_date',
            key: 'ans_date',
            render: (ans) => {
                if (ans) {
                    return <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>
                } else {
                    return <Tag>미답변</Tag>
                }
            },
            align: "center",
            filters: [
                {
                    text: '진행중 질문',
                    value: 'ing'
                },
                {
                    text: '완료된 질문',
                    value: 'clear'
                }
            ],
            onFilter: (value, record) => {
                if (value === 'ing') {
                    return record.ans_date === null
                } else if (value === 'clear') {
                    return record.ans_date !== null
                }
            }
        }
    ]

    const getUserDate = async () => {
        try {
            const {data} = await axios.get(`${constants.apiUrl}/ops/user/${params.id}`);
            setUserData(data);
        } catch (e) {
            console.error(e);
            alert('유저정보를 받아오지 못했습니다.')
        }
    };
    useEffect(() => {
        getUserDate();
    }, [])

    return (
        <div className={"detailMainContainer"}>
            <Typography.Title level={1}>사용자 상세 보기</Typography.Title>
            <Descriptions bordered>
                <Descriptions.Item label="Id" span={3}>{userData?.id}</Descriptions.Item>
                <Descriptions.Item label="이름">{userData?.name}</Descriptions.Item>
                <Descriptions.Item label="닉네임">{userData?.nick}</Descriptions.Item>
                <Descriptions.Item label="가입일">{momentTz(userData?.reg_date * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</Descriptions.Item>
                <Descriptions.Item label="전화 번호">{userData?.tel}</Descriptions.Item>
                <Descriptions.Item label="학생/선생님">{userData?.user_type === 2 ? '선생님' : '학생'}</Descriptions.Item>
                <Descriptions.Item label="고급모드 활성화 여부">{userData?.is_premium === 1 ?
                    <Tag color={'yellow'}>활성화</Tag> : ''}</Descriptions.Item>
                <Descriptions.Item label="로그인 방식">{userData?.provider}</Descriptions.Item>
            </Descriptions>
            <div>
                <Typography.Title level={2} className={'title'}>
                    마케팅 동의 여부
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.cid}
                    dataSource={userData?.HIQDB_term_of_service_logs}
                    columns={termsColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    참여 수업 정보
                    <span> {userData?.lectureList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.cid}
                    dataSource={userData?.classList}
                    columns={lectureColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    참여 학원 정보
                    <span> {userData?.classRoomList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.cid}
                    dataSource={userData?.classRoomList}
                    columns={classRoomColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    참여 교재 정보
                    <span> {userData?.bookList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.blid}
                    dataSource={userData?.bookList}
                    columns={booksColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    지목 질문 정보
                    <span> {userData?.qnaList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.id}
                    dataSource={userData?.qnaList}
                    columns={qnaColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                    expandable={{
                        expandedRowRender: (record) => {
                            const imageUrl = record.img ? record.img : record.original_img
                            return <Image
                                width={200}
                                src={`${constants.s3_url}/qna/${imageUrl}.jpg`}
                            />
                        }
                    }}
                ></Table>
            </div>
            {
                userData?.user_type === 2 &&
                <div>
                    <Typography.Title level={2} className={'title'}>
                        내가 답변 완료를 한 문제
                        <span> {userData?.answerQnaList?.length}개</span>
                    </Typography.Title>
                    <Table
                        rowKey={(record) => record?.id}
                        dataSource={userData?.answerQnaList}
                        columns={qnaColumns}
                        size="small"
                        pagination={{position: ["bottomCenter"]}}
                        expandable={{
                            expandedRowRender: (record) => {
                                const imageUrl = record.img ? record.img : record.original_img
                                return <Image
                                    width={200}
                                    src={`${constants.s3_url}/qna/${imageUrl}.jpg`}
                                />
                            }
                        }}
                    ></Table>
                </div>
            }
        </div>
    )
}

export default UserDetailPage