import { gql } from '@apollo/client';

const ALL_TEACHERS = gql`
query GetAllTeachers($academyId: String!) {
    getAllTeachers(academyId: $academyId) {
      id
      type
      name
      nickname
      intro
      img
    }
  }
  `;

export default ALL_TEACHERS;

