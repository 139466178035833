import SearchCommonPage from "../SearchCommonPage";
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import constants from "../../assets/constants";
import styles from "./style.module.scss"


const options = [{
    value: 'name',
    label: '수업명',
},
    {
        value: 'reg_id',
        label: '소유자',
    },
    {
        value: 'pid',
        label: '학원Id',
    }];
const LecturePage = () => {
    const [lectureData, setLectureData] = useState(null)
    const [selectSearchOption, setSearchOption] = useState('name');
    const navigate = useNavigate();
    const location = useLocation()
    const getLecturesData = async () => {
        try {
            const {data} = await axios.get(`${constants.apiUrl}/ops/class${location.search}`)
            setLectureData(data);
        } catch (e) {
            console.error(e);
            alert('수업 정보를 가져오는데 실패했습니다.')
        }
    }
    const changeSearchSelectOption = (option) => {
        setSearchOption(option)
    }
    const getSearchLectureData = async (value) => {
        let paramsInfo = {}
        if (value) {
            switch (selectSearchOption) {
                case 'name' :
                    paramsInfo.name = value
                    break;
                case 'reg_id' :
                    paramsInfo.reg_id = value
                    break;
                case 'pid' :
                    paramsInfo.pid = value
            }
        }
        const searchParams = new URLSearchParams(paramsInfo);
        navigate(`/lecture?${searchParams}`)
    }
    const columns = [
        {
            title: '수업Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/lecture/${id}`}>{id}</Link>,
            align: "center"
        },
        {
            title: '수업명',
            dataIndex: 'name',
            key: 'name',
            align: "center"
        },
        {
            title: '수업 소유자',
            dataIndex: 'reg_id',
            key: 'reg_id',
            render: (id, record) => <Link to={`/user/${id}`}>{record?.reg_name} ({record?.reg?.user?.nick})</Link>,
            align: "center"
        },
        {
            title: '공유 교재수',
            dataIndex: 'book_count',
            key: 'book_count',
            align: "center",
            sorter: (a, b) => a.book_count - b.book_count,

        },
        {
            title: '가입 유저수',
            dataIndex: 'member_count',
            key: 'member_count',
            align: "center",
            sorter: (a, b) => a.member_count - b.member_count,
        },
        {
            title: '소속 학원 Id',
            dataIndex: ['pid'],
            key: ['pid'],
            render: (id) => <Link to={`/class-room/${id}`}>{id}</Link>,
            align: "center"
        },
        {
            title: '소속 학원명',
            dataIndex: ['pid_HIQDB_class', 'name'],
            key: ['pid_HIQDB_class', 'name'],
            align: "center"
        },

    ];

    useEffect(() => {
        getLecturesData();
    }, [])
    return (
        <SearchCommonPage
            columns={columns}
            options={options}
            changeSearchSelectOption={changeSearchSelectOption}
            handleSearch={getSearchLectureData}
            tableData={lectureData}
            rowKey={(record) => record.id}
            rowClassName={(record) => {
                return record.book_count === 0 ? styles.zeroValueRow : ''
            }}
        />
    )
}

export default LecturePage