import { gql } from '@apollo/client';

const TEACHER_MONTHLY_BILLING = gql`
query TeacherMonthlyBilling($academyId: String!, $teacherId: String!, $period: PeriodArg!) {
    teacherMonthlyBilling(academyId: $academyId, teacherId: $teacherId, period: $period) {
      title
      year
      month
      items {
        name
        actionType
        quantity
        freeQuantity
        charge
        unitCharge
        paybyRate
        paybyCharge
      }
      totalCharge
    }
  }
`;

export default TEACHER_MONTHLY_BILLING;