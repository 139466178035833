import {Select, Space, Table} from "antd";
import Search from "antd/es/input/Search";

const SearchCommonPage = ({options, changeSearchSelectOption, columns, tableData, searchChildren,handleSearch,rowKey,rowClassName}) => {
  return (
    <>
      <Space direction={"vertical"} className={'searchContainer'}>
        <Space>
          <Select
            defaultValue={options[0].label}
            style={{
              width: 120,
            }}
            onChange={changeSearchSelectOption}
            options={options}
          />
          <Search
            placeholder="검색어를 입력해주세요."
            style={{width: 200}}
            onSearch={handleSearch}
            allowClear={true}
          />
        </Space>
        {searchChildren}
      </Space>
      <Table
        rowKey={rowKey}
        dataSource={tableData}
        columns={columns}
        rowClassName={rowClassName}
        pagination={{position:["bottomCenter"]}}
      />
    </>
  )
}

export default SearchCommonPage;