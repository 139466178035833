import React, { useCallback } from "react";
import { DatePicker } from "antd";

const SelectDate = ({ period, setPeriod }) => {
    const onChangeDate = useCallback((e) => {
        setPeriod({
            year: e.year(),
            month: e.month() + 1
        });
    }, [setPeriod]);

    return (
        <DatePicker
            onChange={onChangeDate}
            placeholder="정산 기간"
            picker="month"
            month={period.month - 1}
            year={period.year} />
    )
}

export default SelectDate;