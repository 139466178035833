import {DatePicker, Radio, Select, Space, Table} from "antd";
import {useEffect, useMemo, useState} from "react";
import {Link, useLocation, useNavigate,} from "react-router-dom";
import axios from "axios";
import moment from 'moment'
import momentTz from 'moment-timezone'
import constants from "../../assets/constants";
import Search from "antd/es/input/Search";

const options = [
    {
        value: 'name',
        label: '이름',
    },
    {
        value: 'nick',
        label: '닉네임',
    },
    {
        value: 'id',
        label: 'id'
    }
];
const UserPage = () => {
    const [selectSearchOption, setSelectSearchOption] = useState('name');
    const [userDataList, setUserDataList] = useState(null);
    const [selectSearchUserType, setSelectSearchUserType] = useState(0);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const changeSearchSelectOption = (value) => {
        setSelectSearchOption(value);
    };

    const searchSection = useMemo(() => {

    }, [])
    const getSearchUserData = async (value) => {
        let paramsInfo = {};
        if (value) {
            switch (selectSearchOption) {
                case 'id' :
                    paramsInfo.id = value
                    break;
                case 'nick' :
                    paramsInfo.nick = value
                    break;
                case 'name' :
                    paramsInfo.name = value
            }
        }
        if (startDate && endDate) {
            paramsInfo.start_date = Math.round(new Date(startDate) / 1000);
            paramsInfo.end_date = Math.round(new Date(endDate) / 1000);
        } else if (startDate) {
            paramsInfo.start_date = Math.round(new Date(startDate) / 1000);
        } else if (endDate) {
            paramsInfo.end_date = Math.round(new Date(endDate) / 1000);
        }
        const searchParams = new URLSearchParams(paramsInfo);
        navigate(`/user?${searchParams}`)
    }

    const getUsersData = async () => {
        try {
            const {data} = await axios.get(`${constants.apiUrl}/ops/user${location.search}`)
            setUserDataList(data);
        } catch (e) {
            alert('유저 정보를 받아오지 못했습니다.');
            console.error(e);
        }
    }
    const changeDate = (event, dateString, typeCheck) => {
        if (typeCheck === 'start') {
            setStartDate(event);
        } else if (typeCheck === 'end') {
            setEndDate(event)
        }
    }
    const dateDisabled = (current, typeCheck) => {
        if (endDate && typeCheck === 'start') {
            const customEndDate = new Date(endDate);
            return current && current > moment(customEndDate, 'YYYY-MM-DD');
        } else if (startDate && typeCheck === 'end') {
            const customStartDate = new Date(startDate);
            return (current && current < moment(customStartDate, 'YYYY-MM-DD')) || (current > moment().endOf('day'));
        } else {
            return current > moment().endOf('day');
        }
    }
    const changeSearchUserType = (event) => {
        const userType = event.target.value
        setSelectSearchUserType(userType)
        if (userType === 0) navigate(`/user`)
        else navigate(`?type=${userType}`)
    }

    useEffect(() => {
        setSelectSearchUserType(location.search === '' ? 0 : Number(location.search.split('?type=')[1]))
        getUsersData();
    }, []);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Link to={`/user/${text}`}>{text}</Link>
                )
            },
            align: 'center',
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '닉네임',
            dataIndex: 'nick',
            key: 'nick',
            align: 'center',
        },
        {
            title: '휴대폰',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
        },
        {
            title: '가입일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (regDate) => <span>{momentTz(regDate * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            align: 'center',
        },
        {
            title: '수업수',
            dataIndex: 'classCount',
            key: 'classCount',
            align: 'center',
        },
        {
            title: '속한 학원 수',
            dataIndex: 'classRoomCount',
            key: 'classRoomCount',
            align: 'center',
        },
        {
            title: '사용자 타입',
            dataIndex: 'user_type',
            key: 'user_type',
            render: (user_type) => <span>{user_type === 1 ? '학생' : '선생님'}</span>,
            align: 'center',
        }
    ];
    return (
        <section>
            <Space direction={"vertical"} className={'searchContainer'}>
                <Space>
                    <Select
                        defaultValue={options[0].label}
                        style={{
                            width: 120,
                        }}
                        onChange={changeSearchSelectOption}
                        options={options}
                    />
                    <Search
                        placeholder="검색어를 입력해주세요."
                        style={{width: 200}}
                        onSearch={getSearchUserData}
                        allowClear={true}
                    />
                </Space>
                <div>
                    <Space>
                        <DatePicker
                            placeholder={'Start Date'}
                            value={startDate}
                            onChange={(event, dateString) => changeDate(event, dateString, 'start')}
                            disabledDate={(current) => dateDisabled(current, 'start')}
                        />
                        <DatePicker
                            placeholder={'End Date'}
                            value={endDate}
                            onChange={(event, dateString) => changeDate(event, dateString, 'end')}
                            disabledDate={(current) => dateDisabled(current, 'end')}
                        />
                    </Space>
                    <Radio.Group
                        onChange={(e) => changeSearchUserType(e)}
                        value={selectSearchUserType}>
                        <Radio value={0}>모두</Radio>
                        <Radio value={1}>학생</Radio>
                        <Radio value={2}>선생님</Radio>
                    </Radio.Group>
                </div>
            </Space>
            <Table
                rowKey={(userDataList) => userDataList.id}
                dataSource={userDataList}
                columns={columns}
                pagination={{position: ["bottomCenter"]}}
            />
        </section>
    )
}
export default UserPage