import React, { useContext, useEffect, useState, useCallback } from "react";
import styles from './style.module.scss';
import { Row, Spin, DatePicker, Typography, Space, Button } from "antd";
import PayMethod from "./PayMethod";
import TicketPricePolicy from "./TicketPricePolicy";
import PricePolicy from "./PricePolicy";

const PolicyPage = ({ classroomData }) => {

    return (
        <div className={styles.policyContainer}>
            <PayMethod />
            <TicketPricePolicy />
            <PricePolicy />
        </div>
    )
}

export default PolicyPage;