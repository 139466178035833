import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import styles from './style.module.scss';
import TicketPolicySection from "./TicketPolicySection";
import FIND_ACADEMY from "../../graphql/findAcademy";
import { Spin } from "antd";

const AppPage = () => {
    const APP_ACADEMY_ID = "HIQSUM";
    const { loading, error, data } = useQuery(
        FIND_ACADEMY,
        {
            fetchPolicy: "network-only",
            variables: { academyId: APP_ACADEMY_ID },
        }
    )
    const academyData = data?.findAcademy;
    if (loading) {
        return <Spin />
    }
    return (
        <div className={styles.appContainer}>
            <TicketPolicySection academyData={academyData} />
        </div>
    )
}

export default AppPage;