import { gql } from '@apollo/client';

const ADD_PRICE_POLICY = gql`
mutation AddPricePolicy($actionType: AcademyActionType!, $price: Int!, $freeRange: Int!, $validFrom: String!) {
    addPricePolicy(actionType: $actionType, price: $price, freeRange: $freeRange, validFrom: $validFrom) {
      id
      title
      desc
      actionType
      price
      freeRange
      currentMonth
      validFrom
    }
  }
  `;

export default ADD_PRICE_POLICY;    