import { gql } from '@apollo/client';

const UPDATE_PRICE_POLICY = gql`
mutation UpdatePricePolicy($id: String!, $price: Int!, $freeRange: Int!) {
    updatePricePolicy(id: $id, price: $price, freeRange: $freeRange) {
      id
      title
      desc
      actionType
      price
      freeRange
      currentMonth
      validFrom
    }
  }
  `;

export default UPDATE_PRICE_POLICY;    