import { gql } from '@apollo/client';

const SET_ACADEMY_USE_PAYMENT = gql`
mutation SetAcademyUsePayment($academyId: String!, $usePayment: Boolean!) {
  setAcademyUsePayment(academyId: $academyId, usePayment: $usePayment) {
    academy {
      id
      name
      ticketPolicies {
        name
        comment
        policy
      }
      usePayment
    }
    error {
      code
      message
    }
    success
    responseTime
  }
}
  `;
  
  export default SET_ACADEMY_USE_PAYMENT;