import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import TEACHER_ACTIONS from "../graphql/academyTeacherActions";

function useTeacherLogs(academyId, teacherId, actionType, period) {
    const [actions, setActions] = useState(null);
    const { loading, error, data } = useQuery(
        TEACHER_ACTIONS,
        {
            fetchPolicy: "network-only",
            variables: { academyId, teacherId, actionType, period },
        }
    )
    const teacherLogs = data?.teacherActions;
    useEffect(() => {
        if (teacherLogs) {
            setActions(teacherLogs);
        }
    }, [teacherLogs]);
    return actions;
}

export default useTeacherLogs;