import React, { useContext, useEffect, useState, useCallback } from "react";
import { Space, Typography, Row, Col, Button, Spin, Modal, notification, InputNumber } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import styles from "./style.module.scss";
import ALL_PRICE_POLICIES from "../../graphql/allPricePolicies";
import UPDATE_PRICE_POLICY from "../../graphql/updatePricePolicy";
import ADD_PRICE_POLICY from "../../graphql/addPricePolicy";

const PriceItem = ({
    id,
    title,
    desc,
    actionType,
    price,
    freeRange,
    onClickAdd,
    onClickEdit,
    currentMonth,
    validFrom
}) => (
    <Col style={{ alignItems: "center", padding: 0 }}>
        <Row style={{ alignItems: "center", justifyContent: 'space-between' }}>
            <Typography.Title level={5} mark style={{ margin: 0 }}>{title}</Typography.Title>
            <Typography.Text>{new Date(validFrom).toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })}~</Typography.Text>
        </Row>
        <Row>
            <Col style={{ width: 120 }} align="start">
                <Typography.Text strong>{"단위가격"}</Typography.Text>
            </Col>
            <Col flex={4} align="start">
                <Typography.Text>{price}</Typography.Text>
            </Col>
        </Row>
        <Row>
            <Col style={{ width: 120 }} align="start">
                <Typography.Text strong>{"무료 제공량"}</Typography.Text>
            </Col>
            <Col flex={4} align="start">
                <Typography.Text>{freeRange}</Typography.Text>
            </Col>
        </Row>
        <Row style={{ gap: 8, justifyContent: "flex-end" }}>
            {
                currentMonth ?
                    (<Button onClick={onClickEdit}>수정</Button>) :
                    (<Button onClick={onClickAdd}>추가</Button>)
            }
        </Row>
    </Col>
);

const PricePolicy = () => {
    const [api, contextHolder] = notification.useNotification();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [actionType, setActionType] = useState();
    const [policyId, setPolicyId] = useState();
    const [title, setTitle] = useState();
    const [price, setPrice] = useState();
    const [freeRange, setFreeRange] = useState();

    const { loading, error, data, refetch } = useQuery(ALL_PRICE_POLICIES, {
        fetchPolicy: "no-cache",
    });
    const [updatePolicy, { errors: updateError }] = useMutation(UPDATE_PRICE_POLICY, { ignoreResults: true, onCompleted: refetch });
    const [addPolicy, { errors: createError }] = useMutation(ADD_PRICE_POLICY, { ignoreResults: true, onCompleted: refetch });

    const pricePolicies = data?.allPricePolicies;

    useEffect(() => {
        const err = (error?.graphQLErrors || updateError || createError);
        if (err) {
            const errorMessage = err?.[0]?.extensions?.message;
            openNotification("error", "오류 발생", errorMessage);
        }
    }, [error, updateError, createError]);

    const setSelectedItem = (item) => {
        setActionType();
        setPolicyId(item?.id);
        setTitle(item?.title);
        setPrice(item?.price);
        setFreeRange(item?.freeRange);
    }

    const onClickEdit = useCallback(
        (id) => () => {
            const item = pricePolicies.filter((item) => item.id == id)?.[0];
            setSelectedItem(item);
            setEditModalVisible(true);
        },
        [pricePolicies]
    );

    const onClickAdd = useCallback((actionType) => () => {
        const item = pricePolicies.filter((item) => item.actionType == actionType)?.[0];
        setActionType(actionType);
        setTitle(item?.title);
        setPolicyId();
        setPrice();
        setFreeRange();
        setEditModalVisible(true);
    }, [pricePolicies]);

    const openNotification = useCallback((type, message, description) => {
        api[type]({
            message,
            description,
            placement: 'bottom',
        });
    }, [api]);

    const validateInputs = useCallback(() => {
        if (price == undefined) {
            openNotification("warning", "가격 미설정", "가격을 설정해주세요.");
            return false;
        }
        if (freeRange == undefined) {
            openNotification("warning", "무료구간 미설정", "무료 구간을 설정해주세요.");
            return false;
        }
        return true;
    }, [price, freeRange]);

    const onSaveNew = useCallback(async () => {
        const valid = validateInputs();
        if (valid) {
            // call createPricePolicy mutation!
            let now = new Date();
            let year = now.getFullYear();
            let month = now.getMonth() + 1;
            let validFrom = `${year}-${String(month).padStart(2, '0')}-${String(1).padStart(2, '0')}`
            addPolicy({ variables: { actionType, price, freeRange, validFrom } });
            setSelectedItem();
            setEditModalVisible(false);
        }
    }, [actionType, price, freeRange, validateInputs]);

    const onSaveEdit = useCallback(async () => {
        const valid = validateInputs();
        if (valid) {
            updatePolicy({ variables: { id: policyId, price, freeRange } });
            setSelectedItem();
            setEditModalVisible(false);
        }
    }, [policyId, price, freeRange, updatePolicy]);

    const onCancelEdit = useCallback(() => {
        setActionType();
        setSelectedItem();
        setEditModalVisible(false);
    });

    if (loading) {
        return <Spin />;
    }
    return (
        <>
            {contextHolder}
            <div className={styles.policySection}>
                <div style={{ display: "flex", gap: 24, flexDirection: "column" }}>
                    {pricePolicies.map((item) => (
                        <PriceItem
                            key={item.id}
                            onClickEdit={onClickEdit(item.id)}
                            onClickAdd={onClickAdd(item.actionType)}
                            {...item} />
                    ))}
                </div>
            </div>
            <Modal title={policyId ? "정책 수정" : "정책 생성"}
                open={editModalVisible}
                onOk={policyId ? onSaveEdit : onSaveNew}
                onCancel={onCancelEdit}>
                <Col style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "stretch",
                    padding: 0,
                    gap: 8,
                }}>
                    <Row>
                        <Typography.Title level={5} mark>{title}</Typography.Title>
                    </Row>
                    <Row>
                        <Col style={{ width: 120 }} align="start">
                            <Typography.Text strong>{"단위 가격"}</Typography.Text>
                        </Col>
                        <Col flex={4} align="start">
                            <InputNumber
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                style={{ width: "100%" }}
                                value={price}
                                onChange={(value) => setPrice(value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ width: 120 }} align="start">
                            <Typography.Text strong>{"무료 제공량"}</Typography.Text>
                        </Col>
                        <Col flex={4} align="start">
                            <InputNumber
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                style={{ width: "100%" }}
                                value={freeRange}
                                onChange={(value) => setFreeRange(value)} />
                        </Col>
                    </Row>
                </Col>
            </Modal>
        </>
    );
};

export default PricePolicy;
