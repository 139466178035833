import { gql } from '@apollo/client';

const UPDATE_PAY_METHOD = gql`
mutation UpdatePayMethod($code: String!, $guide: String!) {
    updatePayMethod(code: $code, guide: $guide) {
      code
      guide
      name
    }
  }
  `;

export default UPDATE_PAY_METHOD;  