import { gql } from '@apollo/client';

const ALL_PRICE_POLICIES = gql`
query AllPricePolicies($startDate: String) {
  allPricePolicies(startDate: $startDate) {
    id
    title
    desc
    actionType
    price
    freeRange
    currentMonth
    validFrom
  }
}
`;

export default ALL_PRICE_POLICIES;


