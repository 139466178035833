import React, { useContext, useEffect, useState, useCallback } from "react";
import { Space, Typography, Row, Col, Button, Spin, Modal, Input, InputNumber } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import styles from "./style.module.scss";
import ALL_TICKETS from "../../graphql/allTickets";
import UPDATE_TICKET from "../../graphql/updateTicket";

const TicketItem = ({
    id,
    title,
    desc,
    name,
    price,
    editable = false,
    onClickEdit,
}) => (
    <Col style={{ width: "100%", alignItems: "center", padding: 0 }}>
        <Typography.Title level={5} mark>{name}</Typography.Title>
        <Row>
            <Col style={{ width: 120 }} align="start">
                <Typography.Text strong>{"사용자 제공 이름"}</Typography.Text>
            </Col>
            <Col flex={4} align="start">
                <Typography.Text>{title}</Typography.Text>
            </Col>
        </Row>
        <Row>
            <Col style={{ width: 120 }} align="start">
                <Typography.Text strong>{"사용자 제공 설명"}</Typography.Text>
            </Col>
            <Col flex={4} align="start">
                <Typography.Text>{desc}</Typography.Text>
            </Col>
        </Row>
        <Row>
            <Col style={{ width: 120 }} align="start">
                <Typography.Text strong>{"판매가격"}</Typography.Text>
            </Col>
            <Col flex={4} align="start">
                <Typography.Text>{price}</Typography.Text>
            </Col>
        </Row>
        <Row>
            <Col flex={1} align="end">
                <Button onClick={onClickEdit}>수정</Button>
            </Col>
        </Row>
    </Col>
);

const TicketPricePolicy = () => {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [ticketId, setTicketId] = useState();
    const [title, setTitle] = useState();
    const [desc, setDesc] = useState();
    const [price, setPrice] = useState();

    const { loading, error, data, refetch } = useQuery(ALL_TICKETS, {
        fetchPolicy: "no-cache",
    });
    const [updateTicket, mutationResult] = useMutation(UPDATE_TICKET);

    const tickets = mutationResult?.data?.updateTicket || data?.allTickets;

    const setSelectedItem = (item) => {
        setTicketId(item?.id);
        setTitle(item?.title);
        setDesc(item?.desc);
        setPrice(item?.price);
    }

    const onClickEdit = useCallback(
        (id) => () => {
            const item = tickets.filter((item) => item.id == id)?.[0];
            setSelectedItem(item);
            setEditModalVisible(true);
        },
        [tickets]
    );

    const onSaveEdit = useCallback(async () => {
        updateTicket({ variables: { id: ticketId, price, title, desc } });
        setSelectedItem();
        setEditModalVisible(false);
    }, [ticketId, title, desc, price, updateTicket]);

    const onCancelEdit = useCallback(() => {
        setSelectedItem();
        setEditModalVisible(false);
    });

    if (loading) {
        return <Spin />;
    }
    return (
        <>
            <div className={styles.policySection}>
                {tickets.map((item) => (
                    <TicketItem key={item.id} onClickEdit={onClickEdit(item.id)} {...item} />
                ))}
            </div>
            <Modal title="질문권 수정"
                open={editModalVisible}
                onOk={onSaveEdit}
                onCancel={onCancelEdit}>
                <Col style={{ width: "100%", alignItems: "center", padding: 0 }}>
                    <Row>
                        <Col style={{ width: 120 }} align="start">
                            <Typography.Text strong>{"사용자 제공 이름"}</Typography.Text>
                        </Col>
                        <Col flex={4} align="start">
                            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ width: 120 }} align="start">
                            <Typography.Text strong>{"사용자 제공 설명"}</Typography.Text>
                        </Col>
                        <Col flex={4} align="start">
                            <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ width: 120 }} align="start">
                            <Typography.Text strong>{"판매가격"}</Typography.Text>
                        </Col>
                        <Col flex={4} align="start">
                            <InputNumber value={price} onChange={(value) => setPrice(value)} />
                        </Col>
                    </Row>
                </Col>
            </Modal>
        </>
    );
};

export default TicketPricePolicy;
