import React, { useContext, useEffect, useState, useCallback } from "react";
import styles from './style.module.scss';
import { Row, Spin, DatePicker, Typography, Space, Button } from "antd";
import TeacherBill from "./TeacherBill";

const BillPage = () => {

    return (
        <div className={styles.billContainer}>
            <TeacherBill />
        </div>
    )
}

export default BillPage;