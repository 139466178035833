import { gql } from '@apollo/client';

const SET_ACADEMY_TICKET_POLICY = gql`
mutation SetAcademyTicketPolicy($classId: Int, $academyId: String, $policy: JSON!) {
    setAcademyTicketPolicy(classId: $classId, academyId: $academyId, policy: $policy) {
      success
      responseTime
      error {
        code
        message
      }
      academy {
        id
        name
        ticketPolicies {
          name
          comment
          policy
        }
        usePayment
      }
    }
  }
  `;
  
  export default SET_ACADEMY_TICKET_POLICY;