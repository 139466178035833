import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import TEACHER_MONTHLY_BILLING from "../graphql/teacherMonthlyBilling";

function useTeacherBilling(academyId, teacherId, period) {
    const [billing, setBilling] = useState(null);
    const { loading, error, data } = useQuery(
        TEACHER_MONTHLY_BILLING,
        {
            fetchPolicy: "network-only",
            variables: { academyId, teacherId, period },
        }
    )
    useEffect(() => {
        setBilling(null);
    }, [academyId, teacherId, period]);

    const teacherBilling = data?.teacherMonthlyBilling;
    useEffect(() => {
        if (teacherBilling) {
            setBilling(teacherBilling);
        }
    }, [teacherBilling]);
    return billing;
}

export default useTeacherBilling;