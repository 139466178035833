
export const formatPrice = (price) => {
    if (price == undefined) {
        return
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
}

export const formatQuantity = (actionType, quantity) => {
    console.log("formatQuantity", actionType);
    if (actionType == 'VIDEO_UPLOAD') {
        return formatBytes(quantity);
    } else {
        return "질문 " + quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "개 업로드"
    }
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['바이트', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}