import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import ALL_TEACHERS from "../graphql/getAllTeachers";

function useTeachersOfAcademy(academyId) {
    const [teachers, setTeachers] = useState(null);
    const { loading, error, data, refetch } = useQuery(
        ALL_TEACHERS,
        {
            fetchPolicy: "network-only",
            variables: { academyId },
        }
    );
    const teachersOfAcademy = data?.getAllTeachers;
    useEffect(() => {
        if (teachersOfAcademy) {
            setTeachers(teachersOfAcademy);
        }
    }, [teachersOfAcademy]);

    return teachers;
}

export default useTeachersOfAcademy;