import { gql } from '@apollo/client';

const ALL_PAY_METHODS = gql`
query AllPayMethods {
  allPayMethods {
    code
    guide
    name
  }
}
`;

export default ALL_PAY_METHODS;