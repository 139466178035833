import constants from "../../assets/constants";
import {useEffect, useState} from "react";
import {Pie, PieChart, Cell, Legend, LabelList} from "recharts";
import {Select, Space, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import momentTz from "moment-timezone";
import axios from "axios";
import styles from "../Lecture/style.module.scss";

const DashboardPage = () => {
    const [providerData, setProviderData] = useState([
        {name: 'kakao', value: 0},
        {name: 'naver', value: 0},
        {name: 'apple', value: 0},
        {name: 'other', value: 0},
    ])
    const limitOptions = [
        {
            value: 5,
            label: '5',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
        {
            value: 20,
            label: '20',
        },
    ]
    const [bookList, setBookList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [limitNumber, setLimitNumber] = useState(5);
    const COLORS = ['#FFBB28', '#00C49F', '#000', '#FF8042'];
    const RADIAN = Math.PI / 180;
    const bookColumns = [
        {
            title: '교재Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/book/${id}`}>{id}</Link>,
            align: "center",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: '교재명',
            dataIndex: 'name',
            key: 'name',
            align: "center"
        },
        {
            title: '공유 수업',
            dataIndex: 'class_count',
            key: 'class_count',
            align: "center",
            sorter: (a, b) => a.class_count - b.class_count,
        },
        {
            title: '총 문항수',
            dataIndex: 'total_qna_count',
            key: 'total_qna_count',
            align: "center",
            sorter: (a, b) => a.total_qna_count - b.total_qna_count,
        },
        {
            title: '진행중인 문항수',
            dataIndex: 'ongoging_qna_count',
            key: 'ongoging_qna_count',
            align: "center",
            sorter: (a, b) => a.ongoging_qna_count - b.ongoging_qna_count,
        },
        {
            title: '참여 멤버수',
            dataIndex: 'member_count',
            key: 'member_count',
            align: "center",
            sorter: (a, b) => a.member_count - b.member_count,
        },
        {
            title: '교재 생성일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            align: "center",
            render: (reg_date) => <span>{momentTz(reg_date * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            sorter: (a, b) => a.reg_date - b.reg_date
        },
    ];
    const userColumns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Link to={`/user/${text}`}>{text}</Link>
                )
            },
            align: 'center',
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '닉네임',
            dataIndex: 'nick',
            key: 'nick',
            align: 'center',
        },
        {
            title: '휴대폰',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
        },
        {
            title: '가입일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (regDate) => <span>{momentTz(regDate * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            align: 'center',
        },
        {
            title: '수업수',
            dataIndex: 'classCount',
            key: 'classCount',
            align: 'center',
        },
        {
            title: '속한 학원 수',
            dataIndex: 'classRoomCount',
            key: 'classRoomCount',
            align: 'center',
        },
        {
            title: '사용자 타입',
            dataIndex: 'user_type',
            key: 'user_type',
            render: (user_type) => <span>{user_type === 1 ? '학생' : '선생님'}</span>,
            align: 'center',
        }
    ];
    const classColumns = [
        {
            title: '수업Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/lecture/${id}`}>{id}</Link>,
            align: "center"
        },
        {
            title: '수업명',
            dataIndex: 'name',
            key: 'name',
            align: "center"
        },
        {
            title: '수업 소유자',
            dataIndex: 'reg_id',
            key: 'reg_id',
            render: (id, record) => <Link to={`/user/${id}`}>{record?.reg_name} ({record?.reg?.user?.nick})</Link>,
            align: "center"
        },
        {
            title: '공유 교재수',
            dataIndex: 'book_count',
            key: 'book_count',
            align: "center",
            sorter: (a, b) => a.book_count - b.book_count,

        },
        {
            title: '가입 유저수',
            dataIndex: 'member_count',
            key: 'member_count',
            align: "center",
            sorter: (a, b) => a.member_count - b.member_count,
        },
        {
            title: '소속 학원 Id',
            dataIndex: ['pid'],
            key: ['pid'],
            render: (id) => <Link to={`/class-room/${id}`}>{id}</Link>,
            align: "center"
        },
        {
            title: '소속 학원명',
            dataIndex: ['pid_HIQDB_class', 'name'],
            key: ['pid_HIQDB_class', 'name'],
            align: "center"
        },

    ];
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, value}) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
                {`${value}(${(percent * 100).toFixed(0)}%)`}
            </text>
        );
    };
    const changeUserData = (userData) => {
        return userData.map((user) => {
            let classRoomList = [];
            let lectureList = [];
            user.HIQDB_member_classes?.forEach((memberClass) => {
                const {pid} = memberClass.cid_HIQDB_class
                if (pid && memberClass.status !== 0) {
                    lectureList.push(memberClass);
                } else if (!pid && memberClass.status !== 0) {
                    classRoomList.push(memberClass);
                }
            })
            return {
                ...user,
                lectureCount: lectureList.length,
                classRoomCount: classRoomList.length,
                classRoomList,
                lectureList,
            }
        })
    }
    const getProviderData = async () => {
        try {
            const res = await (await fetch(`${constants.apiUrl}/ops/user/first/provider`)).json();
            setProviderData(prev => {
                const provider = [...prev]
                return provider.map((data) => {
                    return {
                        ...data,
                        value: res.find(({provider}) => (provider.length >= 1 ? provider : 'other') === data.name).count
                    }
                })
            })
        } catch (e) {
            console.error(e)
        }
    }
    const getLimitedBookList = async () => {
        try {
            const res = await (await fetch(`${constants.apiUrl}/ops/book?limit=${limitNumber}`)).json();
            setBookList(res)
        } catch (e) {
            console.error(e)
        }
    }
    const getLimitedUserList = async () => {
        try {
            const res = await (await fetch(`${constants.apiUrl}/ops/user?limit=${limitNumber}`)).json();
            // const reData = changeUserData(res)
            setUserList(res);
        } catch (e) {
            alert('유저 정보를 받아오지 못했습니다.');
            console.error(e);
        }
    }
    const getLimitedClassList = async () => {
        try {
            const res = await (await fetch(`${constants.apiUrl}/ops/class?limit=${limitNumber}`)).json();
            setClassList(res);
        } catch (e) {
            console.error(e);
            alert('수업 정보를 가져오는데 실패했습니다.')
        }
    }

    const handleChangeLimitNumber = (limit) => {
        setLimitNumber(limit)
    }
    useEffect(() => {
        getProviderData()
        getLimitedBookList()
        getLimitedUserList()
        getLimitedClassList()
    }, [limitNumber])

    return (
        <Space wrap={true} size={'middle'} direction={'vertical'} align={'center'} style={{width: '100%'}}>
            <div>
                가져오기 개수
                <Select defaultValue={5} options={limitOptions} onChange={handleChangeLimitNumber}/>
            </div>
            <div>
                <Typography.Title title={2}>처음 회원가입 시 로그인 방식</Typography.Title>
                <PieChart width={500} height={500}>
                    <Legend verticalAlign={'top'} height={36}></Legend>
                    <Pie
                        data={providerData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={200}
                        fill="#8884d8"
                        dataKey="value"
                        minAngle={5}
                        legendType={'circle'}
                    >
                        {providerData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                </PieChart>
            </div>

            <div>
                <Typography.Title title={2}>최근 가입 유저</Typography.Title>
                <Table
                    size={"small"}
                    dataSource={userList}
                    columns={userColumns}
                    rowKey={(record) => record.id}
                />
            </div>
            <div>
                <Typography.Title title={2}>최근 생성 수업</Typography.Title>
                <Table
                    size={"small"}
                    dataSource={classList}
                    columns={classColumns}
                    rowKey={(record) => record.id}
                    rowClassName={(record) => {
                        return record.book_count === 0 ? styles.zeroValueRow : ''
                    }}
                />
            </div>
            <div>
                <Typography.Title title={2}>최근 생성 교재</Typography.Title>
                <Table
                    dataSource={bookList}
                    columns={bookColumns}
                    size={"small"}
                    rowKey={(record) => record.id}
                />
            </div>
        </Space>
    )

}

export default DashboardPage