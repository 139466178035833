import React, { useContext, useEffect, useState, useCallback } from "react";
import { Space, Typography, Row, Col, Empty, Button, Spin, Modal } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import styles from "./style.module.scss";
import ALL_PAY_METHODS from "../../graphql/allPayMethods";
import UPDATE_PAY_METHOD from "../../graphql/updatePayMethod";
import TextEditor from "../../components/TextEditor";

const PayMethodItem = ({ code, guide, name, editable = true, onClickEdit }) => (
    <Row style={{ width: "100%", alignItems: "center", padding: 0 }}>
        <Col style={{ width: 120 }} align="start">
            {editable ?
                (<Typography.Text strong>{name}</Typography.Text>) :
                (<Typography.Title level={5} mark>{name}</Typography.Title>)
            }
        </Col>
        <Col flex={2} align="start" style={{ maxHeight: 64, maxWidth: 180, overflowY: 'hidden', overflowX: "hidden" }}>
            {editable ?
                (<div dangerouslySetInnerHTML={{ __html: guide }} />) :
                (<Typography.Title level={5} mark>{guide}</Typography.Title>)
            }
        </Col>
        <Col flex={1} align="end">
            {editable && <Button onClick={onClickEdit}>수정</Button>}
        </Col>
    </Row>
);

const PayMethod = () => {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [guide, setGuide] = useState();
    const [code, setCode] = useState();

    const { loading, error, data, refetch } = useQuery(ALL_PAY_METHODS, {
        fetchPolicy: "no-cache"
    });
    const [updatePayMethod, mutationResult] = useMutation(
        UPDATE_PAY_METHOD,
    );

    const payMethods = mutationResult?.data?.updatePayMethod || data?.allPayMethods;

    const onClickEdit = useCallback((code) => () => {
        const item = payMethods.filter(item => item.code == code)?.[0];
        setCode(code);
        setGuide(item?.guide);
        setEditModalVisible(true);
    }, [payMethods]);

    const onSaveEdit = useCallback(async () => {
        updatePayMethod({ variables: { code, guide } });
        setCode();
        setGuide();
        setEditModalVisible(false);
    }, [guide, code, updatePayMethod, refetch]);

    const onCancelEdit = useCallback(() => {
        setCode();
        setGuide();
        setEditModalVisible(false);
    });

    const onChangeGuide = useCallback((content) => {
        setGuide(content);
    })

    if (loading) {
        return <Spin />;
    }
    return (
        <>
            <div className={styles.policySection}>
                {[
                    { code: "method", name: "결제수단", guide: "사용자 안내", editable: false },
                    ...payMethods,
                ].map((item) => (
                    <PayMethodItem
                        key={item.code}
                        onClickEdit={onClickEdit(item.code)}
                        {...item} />
                ))}
            </div>
            <Modal title="결제수단 사용자안내"
                open={editModalVisible}
                onOk={onSaveEdit}
                onCancel={onCancelEdit}>
                <TextEditor
                    value={guide}
                    onChange={onChangeGuide} />
            </Modal>
        </>
    );
};

export default PayMethod;
