import React, {useEffect, useState} from 'react';
import './App.css';
import {Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import LoginPage from "./pages/Login/LoginPage";
import {Divider, Layout, Menu, Space, theme, Typography} from 'antd';
import NotFoundPage from "./pages/NotFoundPage";
import UserPage from "./pages/User/UserPage";
import LecturePage from "./pages/Lecture/LecturePage";
import BookPage from "./pages/Book/BookPage";
import ClassRoomPage from "./pages/ClassRoom/ClassRoomPage";
import UserDetailPage from "./pages/User/UserDetailPage";
import LectureDetailPage from "./pages/Lecture/LectureDetailPage";
import BookDetailPage from "./pages/Book/BookDetailPage";
import ClassRoomDetailPage from "./pages/ClassRoom/ClassRoomDetailPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import PolicyPage from './pages/Policy';
import BillPage from './pages/Bill';
import AppPage from './pages/App';

const {Header, Content, Footer, Sider} = Layout;

const items = [
    {key: '0', label: <Link to={'/'}>대시 보드</Link>},
    {key: '1', label: <Link to={'/user'}>사용자 조회</Link>},
    {key: '2', label: <Link to={'/lecture'}>수업 조회</Link>},
    {key: '3', label: <Link to={'/book'}>교재 조회</Link>},
    {key: '4', label: <Link to={'/class-room'}>학원 조회</Link>},
    {key: '5', label: <Link to={'/policy'}>정책</Link>},
    {key: '6', label: <Link to={'/bill'}>정산</Link>},
    {key: '7', label: <Link to={'/app'}>앱 관리</Link>}
];

function App() {
    const navigator = useNavigate();
    const [selectMenu, setSelectMenu] = useState(0);
    const [userInfo, setUserInfo] = useState(null)

    const clickLogout = () => {
        const isOk = window.confirm('로그아웃을 하시겠습니까?');
        if (isOk) {
            setUserInfo(null);
            window.localStorage.clear();
            navigator('/', {replace: true});
        }
    }

    const MainLayout = () => {
        const {token: {colorBgContainer}} = theme.useToken();

        if (!userInfo) {
            return (
                <>
                    <LoginPage setUserInfo={setUserInfo} userInfo={userInfo}/>
                </>
            )

        }
        return (
            <Layout hasSider>
                <Sider
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                    }}
                >
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={`${selectMenu}`} items={items}
                          onClick={(e) => setSelectMenu(e.key)}/>
                </Sider>
                <Layout className="site-layout" style={{marginLeft: 200}}>
                    <Header className={'header'} style={{background: colorBgContainer, textAlign: "end"}}>
                        <Space split={<Divider type="vertical"/>}>
                            <Typography>{userInfo?.name} ( {userInfo?.nick} )</Typography>
                            <Typography onClick={clickLogout} className={'logoutText'}>로그아웃</Typography>
                        </Space>
                    </Header>
                    <Content style={{margin: '24px 16px 0', overflow: 'initial', background: colorBgContainer}}>
                        <Routes>
                            <Route path="/" element={<DashboardPage/>}/>
                            <Route path="user" element={<UserPage/>}/>
                            <Route path="user/:id" element={
                                <UserDetailPage/>}/>
                            <Route path="lecture" element={<LecturePage/>}/>
                            <Route path="lecture/:id" element={<LectureDetailPage/>}/>
                            <Route path="book" element={<BookPage/>}/>
                            <Route path="book/:id" element={<BookDetailPage/>}/>
                            <Route path="class-room" element={<ClassRoomPage/>}/>
                            <Route path="class-room/:id" element={<ClassRoomDetailPage/>}/>
                            <Route path="policy" element={<PolicyPage />}/>
                            <Route path="bill" element={<BillPage />}/>
                            <Route path="app" element={<AppPage />}/>
                        </Routes>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Powered by HiQSum</Footer>
                </Layout>
            </Layout>
        );
    }


    return <MainLayout/>
}

export default App;
