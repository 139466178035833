import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {Descriptions, Table, Image, Space, Tag, Typography} from "antd";
import constants from "../../assets/constants";
import momentTz from "moment-timezone";
import basicBookCover from '../../assets/images/basicBookCover.png'

const BookDetailPage = () => {
    const params = useParams();
    const [bookData, setBookData] = useState(null);

    const getBookData = async () => {
        try {
            const res = await axios.get(`${constants.apiUrl}/ops/book/${params.id}`)
            setBookData(res.data);
        } catch (err) {
            console.error(err);
            alert('책정보를 가져오는데 실패했습니다.')
        }
    }
    useEffect(() => {
        getBookData();
    }, []);
    const classColumns = [
        {
            title: '수업 Id',
            dataIndex: ['cid_HIQDB_class', 'id'],
            ket: 'id',
            render: (text) => <Link to={`/lecture/${text}`}>{text}</Link>,
            align: "center"
        },
        {
            title: '수업명',
            dataIndex: ['cid_HIQDB_class', 'name'],
            key: 'name',
            align: "center"
        },
        {
            title: '기본 교재',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (type) => {
                if (type === 11) {
                    return <Tag color={'blue'}>기본 교재</Tag>
                }
            }
        },
    ]
    const memberColumns = [
        {
            title: '유저 ID',
            dataIndex: 'mid',
            key: 'mid',
            render: (id) => <Link to={`/user/${id}`}>{id}</Link>,
            align: 'center',
        },
        {
            title: '유저명',
            dataIndex: ['mid_HIQDB_user_credential', 'user', 'name'],
            key: ['mid_HIQDB_user_credential', 'user', 'name'],
            render: (name, record) => <span>{name}({record?.mid_HIQDB_user_credential?.user?.nick})</span>,
            align: "center",

        },
        {
            title: '유저타입',
            dataIndex: ['mid_HIQDB_user', 'user_type'],
            key: ['mid_HIQDB_user', 'user_type'],
            render: (text) => <span>{text === 1 ? '학생' : '선생님'}</span>,
            align: "center",
            filters: [
                {
                    text: '학생',
                    value: '1'
                },
                {
                    text: '선생님',
                    value: '2'
                }
            ],
            onFilter: (value, record) => record?.mid_HIQDB_user?.user_type === Number(value),
        },
        {
            title: '유저 상태',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let content = null;
                switch (status) {
                    case 0 :
                        content = '승인 대기 중'
                        break
                    case 9 :
                        content = '초대 수락 대기중'
                        break
                    default :
                        content = '참여'
                }
                return <Tag>{content}</Tag>
            },
            align: "center"
        },
        {
            title: '유저 권한',
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                let typeName = '';
                switch (type) {
                    case 1 :
                        typeName = '열람';
                        break;
                    case 2 :
                        typeName = '참여';
                        break;
                    case 4 :
                        typeName = '구독 학생';
                        break;
                    case 5 :
                        typeName = '관리자';
                        break;
                    case 6:
                        typeName = '일반';
                        break;
                    case 7:
                        typeName = '조교';
                        break;
                    case 8:
                        typeName = '구독 선생님';
                        break;
                    case 9:
                        typeName = '초대 대기 상태'
                }
                return <Tag color={"blue"}>{typeName}</Tag>
            },
            align: "center"
        }
    ];
    const qnaColumns = [
        {
            title: '질문 Id',
            dataIndex: ['qid_HIQDB_online_qna', 'id'],
            ket: 'id',
            align: "center",
            render: (id, record) => <Link
                to={`${constants.message_talk_url}/talk/student/null/${id}/${record?.qid_HIQDB_online_qna?.sid}/${record?.qid_HIQDB_online_qna?.tid}`}
                target="_blank">{id}</Link>
        },
        {
            title: '질문 학생',
            dataIndex: ['qid_HIQDB_online_qna', 'sid'],
            key: 'sid',
            render: (sid, record) => {
                if (sid) {
                    return <Link
                        to={`/user/${sid}`}> {record?.qid_HIQDB_online_qna?.sid_HIQDB_user_credential?.user?.name}({record?.qid_HIQDB_online_qna?.sid_HIQDB_user_credential?.user?.nick})</Link>
                } else {
                    return <Tag color={"blue"}>모범 문제</Tag>
                }
            },
            align: "center",
            filters: [
                {
                    text: '모범문제',
                    value: null
                },
            ],
            onFilter: (value, record) => record?.qid_HIQDB_online_qna?.sid === value
        },
        {
            title: '질문 받은 선생님',
            dataIndex: ['qid_HIQDB_online_qna', 'tid'],
            key: 'type',
            render: (tid, record) => <Link
                to={`/user/${tid}`}>{record?.qid_HIQDB_online_qna?.tid_HIQDB_user_credential?.user?.name}({record?.qid_HIQDB_online_qna?.tid_HIQDB_user_credential?.user?.nick})</Link>,
            align: "center"
        },
        {
            title: '질문 등록일',
            dataIndex: ['qid_HIQDB_online_qna', 'reg_date'],
            key: 'reg_date',
            render: (reg) => <span>{momentTz(reg * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            align: "center"
        },
        {
            title: '답변 완료일',
            dataIndex: ['qid_HIQDB_online_qna', 'ans_date'],
            key: 'ans_date',
            render: (ans) => {
                if (ans) {
                    return <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>
                } else {
                    return <Tag>미답변</Tag>
                }
            },
            align: "center",
            filters: [
                {
                    text: '진행중 질문',
                    value: 'ing'
                },
                {
                    text: '완료된 질문',
                    value: 'clear'
                }
            ],
            onFilter: (value, record) => {
                if (value === 'ing') {
                    return record?.qid_HIQDB_online_qna?.ans_date === null
                } else if (value === 'clear') {
                    return record?.qid_HIQDB_online_qna?.ans_date !== null
                }
            }
        }
    ]
    return (
        <div>
            <Typography.Title level={1}>교재 상세 보기</Typography.Title>
            <Space style={{width: '100%', padding: '1rem'}}>
                <Descriptions bordered>
                    <Descriptions.Item label="교재 Id" span={3}>{bookData?.id}</Descriptions.Item>
                    <Descriptions.Item label="교재명">{bookData?.name}</Descriptions.Item>
                    <Descriptions.Item label="교재 소유자"><Link
                        to={`/user/${bookData?.reg_id}`}>{bookData?.reg?.user?.name}({bookData?.reg?.user?.nick})</Link></Descriptions.Item>
                </Descriptions>
                <Image
                    width="20vw"
                    style={{maxWidth: '300px'}}
                    src={bookData?.img ? `${constants.s3_url}/bookCover/${bookData?.img}.jpg` : basicBookCover}
                />
            </Space>
            <div>
                <Typography.Title level={2} className={'title'}>
                    가입 유저 정보
                    <span> {bookData?.memberList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record.blid}
                    dataSource={bookData?.memberList}
                    columns={memberColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    공유 수업 정보
                    <span> {bookData?.HIQDB_book_links?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.blid}
                    columns={classColumns}
                    dataSource={bookData?.HIQDB_book_links}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                >
                </Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    등록 질문 정보
                    <span> {bookData?.qnaList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record?.qid_HIQDB_online_qna?.id}
                    dataSource={bookData?.qnaList}
                    columns={qnaColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                    expandable={{
                        expandedRowRender: (record) => {
                            const imageUrl = record.qid_HIQDB_online_qna.img ? record.qid_HIQDB_online_qna.img : record.qid_HIQDB_online_qna.original_img
                            return <Image
                                width={200}
                                src={`${constants.s3_url}/qna/${imageUrl}.jpg`}
                            />
                        }
                    }}
                ></Table>
            </div>
        </div>
    )
}

export default BookDetailPage