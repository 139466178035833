import axios from "axios";
import {useEffect, useState} from "react";
import SearchCommonPage from "../SearchCommonPage";
import {Link, useLocation, useNavigate} from "react-router-dom";
import constants from "../../assets/constants";
import momentTz from "moment-timezone";

const options = [
    {
        value: 'name',
        label: '교재명',
    },
    {
        value: 'id',
        label: '교재Id',
    },
];
const BookPage = () => {
    const [bookData, setBookData] = useState(null);
    const [searchOption, setSearchOption] = useState('name');
    const navigate = useNavigate();
    const location = useLocation();
    const getSearchBook = async (value) => {
        const params = {}
        switch (searchOption) {
            case 'name':
                params.name = value;
                break;
            case 'id':
                params.id = value;
        }
        const searchParams = new URLSearchParams(params);
        navigate(`/book?${searchParams}`)
    }
    const getBooks = async () => {
        try {
            const res = await axios.get(`${constants.apiUrl}/ops/book${location.search}`);
            setBookData(res.data);
        } catch (e) {
            console.error(e)
            alert('책목록을 가져오는데 실패했습니다.')
        }
    }
    const changeSearchSelectOption = (value) => {
        setSearchOption(value)
    }
    useEffect(() => {
        getBooks()
    }, [])
    const columns = [
        {
            title: '교재Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/book/${id}`}>{id}</Link>,
            align:"center",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: '교재명',
            dataIndex: 'name',
            key: 'name',
            align:"center"
        },
        {
            title: '공유 수업',
            dataIndex: 'class_count',
            key: 'class_count',
            align:"center",
            sorter: (a, b) => a.class_count - b.class_count,
        },
        {
            title: '총 문항수',
            dataIndex: 'total_qna_count',
            key: 'total_qna_count',
            align:"center",
            sorter: (a, b) => a.total_qna_count - b.total_qna_count,
        },
        {
            title: '진행중인 문항수',
            dataIndex: 'ongoging_qna_count',
            key: 'ongoging_qna_count',
            align:"center",
            sorter: (a, b) => a.ongoging_qna_count - b.ongoging_qna_count,
        },
        {
            title: '참여 멤버수',
            dataIndex: 'member_count',
            key: 'member_count',
            align:"center",
            sorter: (a, b) => a.member_count - b.member_count,
        },
        {
            title: '교재 생성일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            align:"center",
            render: (reg_date)=><span>{momentTz(reg_date * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            sorter:(a,b)=>a.reg_date - b.reg_date
        },
    ];
    return (
        <SearchCommonPage
            columns={columns}
            options={options}
            changeSearchSelectOption={changeSearchSelectOption}
            handleSearch={getSearchBook}
            tableData={bookData}
            rowKey={(record) => record.id}
        />
    )
}

export default BookPage