import { gql } from '@apollo/client';

const ALL_ACADEMIES = gql`
query GetAllAcademies {
    getAllAcademies {
      id
      name
      principal
    }
  }
  `;

export default ALL_ACADEMIES;

