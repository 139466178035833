import React, { useContext, useEffect, useState, useCallback } from "react";
import { Select, Space, Typography, Row, Col, Empty, Button, Spin, Modal } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import styles from './style.module.scss';
import ALL_ACADEMIES from "../../graphql/getAllAcademies";
import useTeachersOfAcademy from "../../hooks/useTeachersOfAcademy";
import useTeacherLogs from "../../hooks/useTeachersLogs";
import useTeacherBilling from "../../hooks/useTeacherBilling";
import SelectDate from "./SelectDate";
import { formatPrice, formatQuantity } from "../../utils/string";

const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const TeacherBill = () => {
    const [period, setPeriod] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
    });
    const [academyId, setAcademyId] = useState();
    const [teacherId, setTeacherId] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [actionType, setActionType] = useState();
    const { loading: academyLoading, error: academyError, data: academyData, refetch } =
        useQuery(ALL_ACADEMIES, {
            fetchPolicy: "no-cache"
        });
    const academies = academyData?.getAllAcademies;
    const teachersOfAcademy = useTeachersOfAcademy(academyId);
    const billing = useTeacherBilling(academyId, teacherId, period);
    const teacherActions = useTeacherLogs(academyId, teacherId, actionType, period);

    const onSelectAcademy = useCallback((academyId) => {
        setAcademyId(academyId);
        setTeacherId();
    }, []);

    const onSelectTeacher = useCallback((teacherId) => {
        setTeacherId(teacherId);
    }, []);

    const onClickDetail = useCallback((actionType) => () => {
        setActionType(actionType);
        setModalVisible(true);
    }, [billing]);

    const onCloseDetail = useCallback(() => {
        setActionType();
        setModalVisible(false);
    })


    const selctedItem = billing?.items?.filter(item => item.actionType == actionType)?.[0];
    const totalPaybyCharge = billing?.items?.reduce((acc, item) => acc + item.paybyCharge, 0) || 0;
    return (
        <>
            <div className={styles.billSection}>
                <Typography.Title level={5}>선생님 정산내역 조회</Typography.Title>
                <Row style={{ display: "flex", gap: 8 }}>
                    <Select
                        showSearch
                        placeholder="학원 선택"
                        optionFilterProp="children"
                        onChange={onSelectAcademy}
                        filterOption={filterOption}
                        options={academies?.map(academy => ({
                            label: academy.name,
                            value: academy.id
                        }))}
                    />
                    <Select
                        showSearch
                        placeholder="선생님 선택"
                        optionFilterProp="children"
                        onChange={onSelectTeacher}
                        filterOption={filterOption}
                        options={teachersOfAcademy?.map(teacher => ({
                            label: `${teacher.name}(${teacher.nickname ?? ''})`,
                            value: teacher.id
                        }))}
                    />
                    <SelectDate period={period} setPeriod={setPeriod} />
                </Row>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 8,
                    padding: 8,
                }}>
                    {
                        (billing?.items?.length || 0) == 0 && (
                            <Empty />
                        )
                    }
                    {
                        billing?.title && (
                            <Row>
                                <Typography.Title level={5} mark>{billing?.title}</Typography.Title>
                            </Row>
                        )
                    }
                    {
                        (billing?.items?.length || 0) > 0 && (
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                padding: "8px 0",
                                gap: 2,
                                borderBottom: "1px solid #F1F1F1"
                            }}>
                                <div style={{ flex: 1 }} />
                                <Typography.Text style={{ flex: 1 }}>선생님 납부금액</Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>학원 납부금액</Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>총 납부금액</Typography.Text>
                                <div style={{ width: 56 }} />
                            </div>
                        )
                    }
                    {
                        billing?.items?.map(item => (
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                padding: "8px 0",
                                gap: 2,
                                borderBottom: "1px solid #F1F1F1"
                            }}>
                                <Typography.Text style={{ flex: 1 }}>
                                    {item.name}
                                </Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>
                                    {formatPrice(item.charge - (item?.paybyCharge || 0))}
                                </Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>
                                    {formatPrice(item?.paybyCharge || 0)}
                                </Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>
                                    {formatPrice(item.charge)}
                                </Typography.Text>
                                <Button onClick={onClickDetail(item.actionType)}>조회</Button>
                            </div>
                        ))
                    }
                    {
                        !!billing ? (
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                padding: "8px 0",
                                gap: 2
                            }}>
                                <Typography.Text strong style={{ flex: 1 }}>
                                    합계
                                </Typography.Text>
                                <Typography.Text strong style={{ flex: 1 }}>
                                    {formatPrice(billing.totalCharge - totalPaybyCharge)}
                                </Typography.Text>
                                <Typography.Text strong style={{ flex: 1 }}>
                                    {formatPrice(totalPaybyCharge)}
                                </Typography.Text>
                                <Typography.Text strong style={{ flex: 1 }}>
                                    {formatPrice(billing.totalCharge)}
                                </Typography.Text>
                                <div style={{ width: 56 }} />
                            </div>
                        ) : null
                    }
                </div>
            </div>

            <Modal title={`${billing?.title} 상세내역`}
                open={modalVisible}
                onOk={onCloseDetail}
                onCancel={onCloseDetail}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "stretch",
                    padding: 8,
                    gap: 8,
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        padding: "8px 0",
                        borderBottom: "1px solid #F1F1F1",
                        marginBottom: 12
                    }}>
                        <Typography.Text strong>단위 가격:</Typography.Text>
                        <Typography.Text strong>{formatPrice(selctedItem?.unitCharge)}</Typography.Text>
                        <span>,</span>
                        <Typography.Text strong>무료 제공량:</Typography.Text>
                        <Typography.Text strong>{selctedItem?.freeQuantity}</Typography.Text>
                        <span>,</span>
                        <Typography.Text strong>학원 납부 비율:</Typography.Text>
                        <Typography.Text strong>{selctedItem?.paybyRate}</Typography.Text>
                    </div>
                    <Row>
                        <Typography.Text strong>활동 내역</Typography.Text>
                    </Row>
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "stretch", width: "100%", maxHeight: 600
                    }}>
                        {
                            teacherActions?.map(item => (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "8px 0",
                                    gap: 2,
                                    borderBottom: "1px solid #F1F1F1"
                                }}>
                                    <Typography.Text strong style={{ flex: 1 }}>
                                        {item.datetime}
                                    </Typography.Text>
                                    <Typography.Text strong style={{ flex: 1 }}>
                                        {formatQuantity(item.action_type, item.quantity)}
                                    </Typography.Text>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default TeacherBill;