import axios from "axios";
import {useEffect, useState} from "react";
import SearchCommonPage from "../SearchCommonPage";
import {Link, useLocation, useNavigate} from "react-router-dom";
import constants from "../../assets/constants";
import momentTz from "moment-timezone";

const options = [
    {
        value: 'name',
        label: '학원명',
    },
    {
        value: 'id',
        label: '학원Id',
    },
    {
        value: 'reg_id',
        label: '학원장Id',
    },
];
const ClassRoomPage = () => {
    const [classroomList, SetClassroomList] = useState(null);
    const [searchOption, setSearchOption] = useState('name');
    const navigate = useNavigate();
    const location = useLocation();

    const getSearchClassroom = async (value) => {
        const params = {}
        switch (searchOption) {
            case 'name':
                params.name = value;
                break;
            case 'id':
                params.id = value;
                break;
            case 'reg_id':
                params.reg_id = value
        }
        const searchParams = new URLSearchParams(params);
        navigate(`/class-room?${searchParams}`)
    }
    const getBooks = async () => {
        try {
            const res = await axios.get(`${constants.apiUrl}/ops/classroom${location.search}`);
            console.log(res.data);
            SetClassroomList(res.data);
        } catch (e) {
            console.error(e)
            alert('책목록을 가져오는데 실패했습니다.')
        }
    }
    const changeSearchSelectOption = (value) => {
        setSearchOption(value)
    }
    useEffect(() => {
        getBooks();
    }, [])
    const columns = [
        {
            title: '학원Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/class-room/${id}`}>{id}</Link>,
            align:"center"
        },
        {
            title: '학원명',
            dataIndex: 'name',
            key: 'name',
            align:"center"
        },
        {
            title: '학원 주소',
            dataIndex: 'address',
            key: 'address',
            align:"center"
        },
        {
            title: '학원 소개',
            dataIndex: 'intro',
            key: 'intro',
            align:"center"
        },
        {
            title: '학원장Id',
            dataIndex: 'reg_id',
            key: 'reg_id',
            render: (id) => <Link to={`/user/${id}`}>{id}</Link>,
            align:"center"
        },
        {
            title: '학원 생성일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            align: "center",
            render: (reg_date)=><span>{momentTz(reg_date * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            sorter: (a, b) => a.reg_date - b.reg_date,
        },
        {
            title: '소속 학생수',
            dataIndex: 'student_count',
            key: 'student_count',
            align: "center",
            sorter: (a, b) => a.student_count - b.student_count,
        },
        {
            title: '소속 선생님수',
            dataIndex: 'teacher_count',
            key: 'teacher_count',
            align: "center",
            sorter: (a, b) => a.teacher_count - b.teacher_count,
        },
        {
            title: '소속 수업수',
            dataIndex: 'class_count',
            key: 'class_count',
            align: "center",
            sorter: (a, b) => a.class_count - b.class_count,
        },
    ];
    return (
        <SearchCommonPage
            columns={columns}
            options={options}
            changeSearchSelectOption={changeSearchSelectOption}
            handleSearch={getSearchClassroom}
            tableData={classroomList}
            rowKey={(record) => record.id}
        />
    )
}

export default ClassRoomPage