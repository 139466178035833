import { gql } from '@apollo/client';

const UPDATE_TICKET = gql`
mutation UpdateTicket($id: Int!, $price: Int, $title: String, $desc: String) {
    updateTicket(id: $id, price: $price, title: $title, desc: $desc) {
      id
      name
      type
      saleType
      price
      title
      desc
    }
  }
  `;

export default UPDATE_TICKET;    