import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useLayoutEffect, useState} from "react";
import axios from "axios";
import {Descriptions, Table, Tag, Typography} from "antd";
import constants from "../../assets/constants";
import momentTz from "moment-timezone";

const ClassRoomDetailPage = ({selectClassRoomData, selectUserData}) => {
    const params = useParams();
    const navigator = useNavigate();
    const [classroomData, setClassroomData] = useState(null);
    const columns = [
        {
            title: '수업 Id',
            dataIndex: 'id',
            ket: 'id',
            render: (text) => <Link to={`/lecture/${text}`}>{text}</Link>,
            align: "center"
        },
        {
            title: '수업명',
            dataIndex: 'name',
            key: 'name',
            align: "center"
        },
        {
            title: '수업 소유주 Id',
            dataIndex: 'reg_id',
            key: 'reg_id',
            align: "center"
        },
        Table.EXPAND_COLUMN,
        {
            title: '공유 교재수',
            dataIndex: 'sharedBooks',
            key: 'sharedBooks',
            align: "center"
        },
    ]
    const memberColums = [
        {
            title: '유저 ID',
            dataIndex: 'mid',
            key: 'mid',
            render: (id) => <Link to={`/user/${id}`}>{id}</Link>,
            align: 'center',
        },
        {
            title: '유저명',
            dataIndex: ['mid_HIQDB_user_credential', 'user', 'name'],
            key: ['mid_HIQDB_user_credential', 'user', 'name'],
            render: (name, record) => <span>{name}({record?.mid_HIQDB_user_credential?.user?.nick})</span>,
            align: "center",

        },
        {
            title: '유저 타입',
            dataIndex: ['mid_HIQDB_user_credential', 'user', 'user_type'],
            key: ['mid_HIQDB_user_credential', 'user', 'user_type'],
            render: (text) => <span>{text === 1 ? '학생' : '선생님'}</span>,
            align: "center",
            filters: [
                {
                    text: '학생',
                    value: '1'
                },
                {
                    text: '선생님',
                    value: '2'
                }
            ],
            onFilter: (value, record) => record?.mid_HIQDB_user_credential?.user?.user_type === Number(value),
        },
        {
            title: '유저 상태',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Tag>{text === 0 ? '승낙 대기중' : '승인'}</Tag>,
            align: "center"
        },
        {
            title: '유저 권한',
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                if (type === 5) {
                    return <Tag color={"blue"}>학원 관리자</Tag>
                }
            },
            align: "center"
        },
        {
            title: '가입일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (ans) => <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>
        }
    ];
    const getClassRoomData = async () => {
        try {
            const res = await axios.get(`${constants.apiUrl}/ops/classroom/${params.id}`);
            res.data.lectureList.map((lecture) => {
                return lecture.sharedBooks = lecture?.HIQDB_book_links?.length
            })
            console.log('lectureData', res.data);
            setClassroomData(res.data);
        } catch (err) {
            console.error(err);
            alert('클래스룸 정보를 받아오지 못했습니다.')
        }
    }
    useEffect(() => {
        getClassRoomData();
    }, []);
    return (
        <div className="detailMainContainer">
            <Typography.Title level={1}>클래스룸 상세 보기</Typography.Title>
            <Descriptions bordered>
                <Descriptions.Item label="클래스룸 Id" span={3}>{classroomData?.id}</Descriptions.Item>
                <Descriptions.Item label="클래스룸명">{classroomData?.name}</Descriptions.Item>
                <Descriptions.Item label="학원 주소" span={2}>{classroomData?.address}</Descriptions.Item>
                <Descriptions.Item label="학원 소개">{classroomData?.intro}</Descriptions.Item>
                <Descriptions.Item label="학원장"><Link
                    to={`/user/${classroomData?.reg?.name}`}>{classroomData?.reg?.user?.name}({classroomData?.reg?.user?.nick})</Link></Descriptions.Item>
                <Descriptions.Item label="학원 전화번호">{classroomData?.tel}</Descriptions.Item>
            </Descriptions>
            <div>
                <Typography.Title level={2} className={'title'}>
                    가입 유저 정보
                    <span> {classroomData?.HIQDB_member_classes?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record.mid_HIQDB_user?.id}
                    dataSource={classroomData?.HIQDB_member_classes}
                    columns={memberColums}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2}>수업 정보</Typography.Title>
                <Table
                    rowKey={(userData) => userData?.id}
                    dataSource={classroomData?.lectureList}
                    columns={columns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                    expandable={{
                        expandedRowRender: (record) => {
                            const subTableCol = [
                                {
                                    title: '교재 ID',
                                    dataIndex: 'bid',
                                    key: 'bid',
                                    render: (text) => <Link to={`/book/${text}`}>{text}</Link>,
                                    align: "center"
                                },
                                {
                                    title: '교재명',
                                    dataIndex: ['bid_HIQDB_book', 'name'],
                                    key: ['bid_HIQDB_book', 'name'],
                                    align: "center"
                                },
                                {
                                    title: '기본 교재 표시',
                                    dataIndex: 'type',
                                    key: 'type',
                                    render: (type) => {
                                        if (type === 11) {
                                            return <Tag color="blue">기본 교재</Tag>
                                        }
                                    },
                                    align: "center"
                                }
                            ]
                            const data = record.HIQDB_book_links;
                            return <Table rowKey={(record) => record.bid} columns={subTableCol} dataSource={data}
                                          pagination={false}/>;
                        }
                    }
                    }
                ></Table>
            </div>
        </div>
    )
}

export default ClassRoomDetailPage