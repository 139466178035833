import style from "./Login.module.scss";
import {Button} from "antd";
import NaverLoginBtnImg from "../../assets/images/NaverLoginBtnImg.png";
import KakaoLoginBtnImg from "../../assets/images/KakaoLoginBtnImg.png";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import constants from "../../assets/constants";

const LoginPage = ({setUserInfo, userInfo}) => {
    const {Kakao, naver} = window;
    const naverRef = useRef();
    const navigate = useNavigate();
    const lastSocial = window.localStorage.getItem('lastSocial')
    const location = useLocation();
    const clickKakaoLoginBtn = () => {
        window.localStorage.setItem('url',location.pathname)
        window.localStorage.setItem('lastSocial', 'Kakao');
        Kakao.Auth.authorize({
            redirectUri: `${constants.redirect_url}`,
        })
    }
    const authKakaoLogin = async () => {
        try {
            const kakaoCode = new URL(window.location.href).searchParams.get("code");
            const resToken = await axios({
                method: "POST",
                url: 'https://kauth.kakao.com/oauth/token',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
                data: {
                    grant_type: 'authorization_code',
                    client_id: constants.kakao_client_id,
                    redirect_uri: constants.redirect_url,
                    code: kakaoCode
                }
            })
            Kakao.Auth.setAccessToken(resToken.data.access_token);

            const resData = await Kakao.API.request({
                url: "/v2/user/me",
            })

            getProviderSignIn(resData, 'Kakao');
        } catch (e) {
            console.log(e.response.status)
        }
    }
    const getProviderSignIn = async (loginData, socialName) => {
        try {
            const {data} = await axios.post(`${constants.apiUrl}/ops/auth/login`, {
                userAuthData: loginData,
                provider: socialName
            });
            if (data) {
                setUserInfo(data.userData);
                navigate(window.localStorage.getItem('url'), {replace: true});
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        const getnaverLogin = () => {
            try {
                const naverLogin = new naver.LoginWithNaverId({
                    clientId: constants.naver_client_id,
                    callbackUrl: constants.redirect_url,
                    isPopup: false, /* 팝업을 통한 연동처리 여부, true 면 팝업 */
                    loginButton: {color: "green", type: 3, height: 52, width: 200},
                });
                naverLogin.init();
                naverLogin.getLoginStatus((status) => {
                    if (status && lastSocial === 'naver') {
                        getProviderSignIn(naverLogin.user, 'naver')
                    }
                })
            } catch (e) {
                console.error(e);
            }
        }
        if (!userInfo) {
            getnaverLogin();
        }
    }, [])
    useEffect(() => {
        if (window.location.href.includes('?code')) {
            authKakaoLogin();
        }
    }, [navigate]);

    const postLoginTestPark = async ()=>{
        try {
            const {data} = await axios.post(`${constants.apiUrl}/admin/login`, {
                id: '0YtcNZweMgR0PkembMND',
                pw: 'TqXFCKZWbnYkBUP4/rBv1Fd3e+OVScQBZDav2mXSMw4='
            });
            setUserInfo(data);
            window.localStorage.setItem("user", JSON.stringify(data));
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={style.loginMainContainer}>
            <div className={style.loginContainer}>
                <div id="naverIdLogin" ref={naverRef}
                     onClick={() => window.localStorage.setItem('lastSocial', 'naver')}>
                    <div>
                        <button>
                            <img src={NaverLoginBtnImg} width={200} alt='naver Logo'/>
                        </button>
                    </div>

                </div>

                <button role={"button"} onClick={clickKakaoLoginBtn}>
                    <img src={KakaoLoginBtnImg} width={200} alt='naver Logo'/>
                </button>
                {process.env.NODE_ENV !== 'production' && <Button
                        onClick={postLoginTestPark}
                    >Park juho</Button>}
            </div>
        </div>
    )
}

export default LoginPage