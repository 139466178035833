import { gql } from '@apollo/client';

const TEACHER_ACTIONS = gql`
query TeacherActions($academyId: String!, $teacherId: String!, $period: PeriodArg, $actionType: AcademyActionType) {
  teacherActions(academyId: $academyId, teacherId: $teacherId, period: $period, actionType: $actionType) {
    actedAt
    datetime
    action_type
    actionType
    quantity
    payload
  }
}
`;

export default TEACHER_ACTIONS;