import ReactQuill from "react-quill";

const TextEditor = ({ value, onChange = () => { } }) => {
    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, false] }],
                ["bold", "underline"],
            ],
        },
    };
    return (
        <>
            <ReactQuill
                style={{ width: "100%" }}
                modules={modules}
                value={value}
                onChange={onChange}
            />
        </>
    );
}

export default TextEditor;