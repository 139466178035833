let apiUrl = ""
let redirect_url = ""
let s3_url = ""
let message_talk_url = ""

if (process.env.NODE_ENV === 'production') {
    apiUrl = `https://api.hiq24.co.kr` // internal
    redirect_url = 'https://ops.hiq24.co.kr/' // internal
    s3_url = "https://api.hiq24.co.kr"
    message_talk_url = `https://com.hiq24.co.kr`//message talk
} else {
    apiUrl = `http://localhost:3000` // server
    redirect_url = 'http://localhost:3001/' // front
    s3_url = "http://localhost:3000" // upload gcp
    message_talk_url = `http://localhost:3002`//message talk
}

const constants = {
    apiUrl: apiUrl, // api 호출 Url
    s3_url: s3_url,
    redirect_url: redirect_url, //
    message_talk_url:message_talk_url,
    kakao_client_id: 'ba17d32598e9f839a6f17b228b3fef64',
    naver_client_id: 'RTGpA0hENM99AhGP0fNm',

}

export default constants

