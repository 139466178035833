import { gql } from '@apollo/client';

const ALL_TICKETS = gql`
query AllTickets {
    allTickets {
      id
      type
      name
      saleType
      price
      title
      desc
    }
  }
`;

export default ALL_TICKETS;


