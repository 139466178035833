import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Descriptions, Image, Table, Tag, Typography} from "antd";
import constants from "../../assets/constants";
import momentTz from "moment-timezone";

const LectureDetailPage = ({selectLectureData}) => {
    const params = useParams();
    const navigator = useNavigate();
    const [lectureData, setLectureData] = useState(null);
    const getLectureData = async () => {
        try {
            const {data} = await axios.get(`${constants.apiUrl}/ops/class/${params.id}`);
            setLectureData(data);
        } catch (e) {
            console.error(e);
            alert('수업 정보를 가져오는데 실패했습니다.');
            navigator(-1);
        }
    }
    const lectureColumns = [
        {
            title: '멤버 이름',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => <Link
                to={`/user/${record?.mid}`}>{record?.mid_HIQDB_user_credential?.user?.name}({record?.mid_HIQDB_user_credential?.user?.nick})</Link>,
            align: "center",

        },
        {
            title: '멤버 타입',
            dataIndex: ['mid_HIQDB_user_credential', 'user', 'user_type'],
            key: ['mid_HIQDB_user_credential', 'user', 'user_type'],
            render: (text) => <span>{text === 1 ? '학생' : '선생님'}</span>,
            align: "center",
            filters: [
                {
                    text: '학생',
                    value: '1'
                },
                {
                    text: '선생님',
                    value: '2'
                }
            ],
            onFilter: (value, record) => record?.mid_HIQDB_user_credential?.user?.user_type === Number(value),
        },
        {
            title: '멤버 상태',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Tag>{text === 0 ? '승낙 대기중' : '승인'}</Tag>,
            align: "center"
        },
        {
            title: '가입일',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (ans) => <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            align: "center"
        }
    ];
    const bookColumns = [
        {
            title: '교재 ID',
            dataIndex: 'bid',
            key: 'bid',
            render: (id) => <Link to={`/book/${id}`}>{id}</Link>,
            align: 'center',
        },
        {
            title: '교재명',
            dataIndex: ['bid_HIQDB_book', 'name'],
            key: ['bid_HIQDB_book', 'name'],
            align: "center"
        },
        {
            title: '교재 타입',
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                if (type === 11) {
                    return <Tag color="blue">기본 교재</Tag>
                }
            },
            align: "center"
        }
    ];
    const qnaColumns = [
        {
            title: '질문 ID',
            dataIndex: 'qid',
            key: 'qid',
            align: 'center',
            render: (id, record) => <Link
                to={`${constants.message_talk_url}/talk/student/null/${id}/${record?.qid_HIQDB_online_qna?.sid}/${record?.qid_HIQDB_online_qna?.tid}`}
                target="_blank">{id}</Link>
        },
        {
            title: '질문 학생',
            dataIndex: ['qid_HIQDB_online_qna', 'sid'],
            key: 'sid',
            render: (sid, record) => {
                if (sid) {
                    return <Link
                        to={`/user/${sid}`}>{record?.qid_HIQDB_online_qna?.sid_HIQDB_user_credential?.user?.name}({record?.qid_HIQDB_online_qna?.sid_HIQDB_user_credential?.user?.nick})</Link>
                } else {
                    return <Tag color={"blue"}>모범 문제</Tag>
                }
            },
            align: "center",
        },
        {
            title: '질문 받은 선생님 ID',
            dataIndex: ['qid_HIQDB_online_qna', 'tid'],
            key: ['qid_HIQDB_online_qna', 'tid'],
            render: (tid, record) => {
                if (tid) {
                    return <Link
                        to={`/user/${tid}`}>{record?.qid_HIQDB_online_qna?.tid_HIQDB_user_credential?.user?.name}({record?.qid_HIQDB_online_qna?.tid_HIQDB_user_credential?.user?.name})</Link>
                }
            },
            align: "center",
        },
        {
            title: '질문 등록일',
            dataIndex: ['qid_HIQDB_online_qna', 'reg_date'],
            key: 'reg_date',
            render: (reg) => <span>{momentTz(reg * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>,
            align: "center"
        },
        {
            title: '답변 완료일',
            dataIndex: ['qid_HIQDB_online_qna', 'ans_date'],
            key: 'ans_date',
            render: (ans) => {
                if (ans) {
                    return <span>{momentTz(ans * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</span>
                } else {
                    return <Tag>미답변</Tag>
                }
            },
            align: "center",
            filters: [
                {
                    text: '진행중 질문',
                    value: 'ing'
                },
                {
                    text: '완료된 질문',
                    value: 'clear'
                }
            ],
            onFilter: (value, record) => {
                if (value === 'ing') {
                    return record?.qid_HIQDB_online_qna?.ans_date === null
                } else if (value === 'clear') {
                    return record?.qid_HIQDB_online_qna?.ans_date !== null
                }
            }
        },
        {
            title: '교재 ID',
            dataIndex: 'bid',
            key: 'bid',
            render: (id) => <Link to={`/book/${id}`}>{id}</Link>,
            align: "center"
        }
    ];

    useEffect(() => {
        getLectureData();
    }, [])
    return (
        <div className={"detailMainContainer"}>
            <Typography.Title level={1}>수업 상세 보기</Typography.Title>
            <Descriptions bordered>
                <Descriptions.Item label="수업 Id" span={3}>{lectureData?.id}</Descriptions.Item>
                <Descriptions.Item label="수업명">{lectureData?.name}</Descriptions.Item>
                <Descriptions.Item label="수업 소유자 ID"><Link
                    to={`/user/${lectureData?.reg_id}`}>{lectureData?.reg_id}</Link></Descriptions.Item>
                <Descriptions.Item
                    label="수업 소유자명">{lectureData?.reg?.user?.name}({lectureData?.reg?.user?.nick})</Descriptions.Item>
                <Descriptions.Item label="수업 소개">{lectureData?.intro}</Descriptions.Item>
                <Descriptions.Item label="소속 학원 ID"><Link
                    to={`/class-room/${lectureData?.pid}`}>{lectureData?.pid}</Link></Descriptions.Item>
                <Descriptions.Item label="소속 학원명">{lectureData?.classroom_name}</Descriptions.Item>
                <Descriptions.Item
                    label="수업 생성일">{momentTz(lectureData?.reg_date * 1000).tz('Asia/Seoul').format('YYYY.MM.DD')}</Descriptions.Item>
            </Descriptions>
            <div>
                <Typography.Title level={2} className={'title'}>
                    가입 유저 정보
                    <span> {lectureData?.HIQDB_member_classes?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record.mid_HIQDB_user?.id}
                    dataSource={lectureData?.HIQDB_member_classes}
                    columns={lectureColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    공유 교재 정보
                    <span> {lectureData?.HIQDB_book_links?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record) => record.bid}
                    dataSource={lectureData?.HIQDB_book_links}
                    columns={bookColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                ></Table>
            </div>
            <div>
                <Typography.Title level={2} className={'title'}>
                    등록 질문 정보
                    <span> {lectureData?.qnaList?.length}개</span>
                </Typography.Title>
                <Table
                    rowKey={(record, records) => 'qna'+ record.bid + records}
                    dataSource={lectureData?.qnaList}
                    columns={qnaColumns}
                    size="small"
                    pagination={{position: ["bottomCenter"]}}
                    expandable={{
                        expandedRowRender: (record) => {
                            const imageUrl = record.qid_HIQDB_online_qna.img ? record.qid_HIQDB_online_qna.img : record.qid_HIQDB_online_qna.original_img
                            return <Image
                                width={200}
                                src={`${constants.s3_url}/qna/${imageUrl}.jpg`}
                            />
                        }
                    }}
                ></Table>
            </div>
        </div>

    )
}

export default LectureDetailPage