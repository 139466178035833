import { gql } from '@apollo/client';

const FIND_ACADEMY = gql`
query FindAcademy($academyId: String!) {
  findAcademy(academyId: $academyId) {
    academy {
      id
      name
      location
      phone
      principal
      ticketPolicies {
        name
        comment
        policy
      }
      usePayment
    }
    error {
      code
      message
    }
    success
    responseTime
  }
}
`;

export default FIND_ACADEMY;